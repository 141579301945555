/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate } from "gatsby"

import "./layout.css"

import vincitLogo from "../images/Vincit_logo_white.svg"

const HStack = styled.div`
  display: flex;
  flex-direction: row;
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
`

const SmileBellLogo = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: #ffffff;
`

const By = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 15px;
  line-height: 15px;
  color: #f3f5f8;
`
const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            background: "#000E31",
            width: "100vw",
          }}
        >
          <VStack
            style={{
              alignItems: "flex-start",
              padding: "24px 0px 0px 24px",
            }}
          >
            <HStack>
              <SmileBellLogo
                onClick={() => {
                  navigate("/")
                }}
              >
                SmileBell®
              </SmileBellLogo>
              <By style={{ padding: "8px" }}>by</By>
              <div>
                <a target="_blank" href="https://vincit.com">
                  <img
                    src={vincitLogo}
                    alt="Vincit"
                    style={{ padding: "4px 0 0 0" }}
                  />
                </a>
              </div>
            </HStack>
          </VStack>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

//<Header siteTitle={data.site.siteMetadata.title} />
